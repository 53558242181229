import React, { useState } from 'react'
import { Trans, useLingui } from '@lingui/react'
import { useQuery } from '@apollo/client'
import useSearch from '../hooks/useSearch'
import useAppState from '../hooks/useAppState'
import usePermissions from '../hooks/usePermissions'
import usePopoverState from '../hooks/usePopoverState'
import useSearchOptionsPreferences from '../hooks/useSearchOptionsPreferences'
import Select from './Select2'
import { QUERY } from './ProjectSearchMultiSelect'
import useAccessibleDropdown from '../hooks/useAccessibleDropdown'

const ProjectSearchSelect = ({
    id = 'project',
    open,
    position,
    name = 'project',
    autoFocus = false,
    disabled = false,
    value = null,
    defaultOptions = [],
    onChange,
    hasChildren = null,
    includeNoProject = false,
    includeActivityRegistration = null,
    ...props
}) => {
    const { i18n } = useLingui()
    const { currentUser } = useAppState()
    const [search, setSearch] = useSearch('')
    const [inputValue, setInputValue] = useState('')
    const [selectedValue, setSelectedValue] = useState(value)
    const {
        preferences: { involvedProjectsOnly },
        handleUpdatePreferences,
    } = useSearchOptionsPreferences()
    const { canReadUninvolvedProjects } = usePermissions()
    const { isOpen, openPopover, closePopover } = usePopoverState(id, open)

    const hasSearch = search !== ''
    const variables = {
        where: {
            statusSlugs: ['active'],
            involvedPartnerIds: involvedProjectsOnly ? [currentUser.id] : [],
            hasChildren: hasChildren === null ? null : hasChildren,
            allowActivityRegistration:
                includeActivityRegistration === null
                    ? null
                    : includeActivityRegistration,
        },
        currentPage: 1,
        pageSize: hasSearch ? 50 : 10,
        orderBy: {
            mostRecentActivityOfLoggedInPartner: 'desc',
        },
        ...(search && {
            search: {
                numberContains: search,
                titleContains: search,
            },
        }),
    }

    const { data = {}, loading } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    })

    let projects = data.projectsPaginated?.nodes || []
    if (includeNoProject) {
        projects = [
            {
                id: -1,
                title: i18n._(/* i18n */ 'No project'),
                number: i18n._(/* i18n */ 'No project'),
            },
            ...projects,
        ]
    }
    if (defaultOptions.length > 0 && !hasSearch) {
        const ids = defaultOptions.map((p) => p.id)
        projects = projects.filter((p) => !ids.includes(p.id))
    }

    const options = [...defaultOptions, ...projects].map((option, index) => ({
        ...option,
        index,
    }))

    const handleClear = () => {
        setSearch('')
        setInputValue('')
        onChange(null)
        setSelectedValue(null)
    }

    const handleChangeInput = (e) => {
        openPopover()
        setSearch(e.target.value)
        setInputValue(e.target.value)
        onChange('')
        setSelectedValue(null)
    }

    const handleChange = (project) => {
        setSearch('')
        setInputValue('')
        onChange(project)
        setSelectedValue(project)
        closePopover()
    }

    const { setIsDropdownOpen, activeIndex, select, setIsFocus, listRef } =
        useAccessibleDropdown({
            value,
            options,
            isOpen,
            onChange: handleChange,
            autoFocus,
            closeAfterSelect: true,
        })

    let finalInputValue = inputValue
    if (selectedValue) {
        finalInputValue = `${selectedValue.number} ${selectedValue.title}`
        if (selectedValue?.id !== value?.id) {
            finalInputValue = `${value?.number} ${value?.title}`
        }
    }

    return (
        <Select
            ref={listRef}
            id={id}
            disabled={disabled}
            position={position}
            content={
                <>
                    <Select.CheckboxOption
                        disabled={!canReadUninvolvedProjects.value}
                        checked={!involvedProjectsOnly}
                        onChange={() =>
                            handleUpdatePreferences({
                                involvedProjectsOnly: !involvedProjectsOnly,
                            })
                        }
                        label={<Trans id="Include uninvolved projects" />}
                    />
                    {(() => {
                        if (loading) {
                            return (
                                <Select.Placeholder>
                                    <Trans id="Loading..." />
                                </Select.Placeholder>
                            )
                        }
                        if (projects.length === 0) {
                            return (
                                <Select.Placeholder>
                                    <Trans id="No results" />
                                </Select.Placeholder>
                            )
                        }
                        return (
                            <>
                                {hasSearch ? null : (
                                    <>
                                        {defaultOptions.length > 0 ? (
                                            <>
                                                <span className="p-3 w-full uppercase text-xs text-gray-500">
                                                    <Trans id="Suggestions" />
                                                </span>
                                                {defaultOptions.map(
                                                    (project, index) => (
                                                        <Select.Option
                                                            key={project.id}
                                                            label={`${project.number} ${project.title}`}
                                                            isActive={
                                                                index ===
                                                                activeIndex
                                                            }
                                                            onClick={() => {
                                                                select(project)
                                                            }}
                                                        />
                                                    ),
                                                )}
                                            </>
                                        ) : null}
                                        <span className="p-3 w-full uppercase text-xs text-gray-500">
                                            <Trans id="Recently used" />
                                        </span>
                                    </>
                                )}
                                {projects.map((project, index) => {
                                    const label =
                                        project.id === -1 ? (
                                            <Trans id="No project" />
                                        ) : (
                                            `${project.number} ${project.title}`
                                        )
                                    return (
                                        <Select.Option
                                            key={project.id}
                                            label={label}
                                            isActive={
                                                defaultOptions.length +
                                                    index ===
                                                activeIndex
                                            }
                                            onClick={() => {
                                                select(project)
                                            }}
                                        />
                                    )
                                })}
                            </>
                        )
                    })()}
                </>
            }
            {...props}
        >
            <Select.SearchInput
                autoComplete="off"
                id={id}
                name={name}
                disabled={disabled}
                value={finalInputValue}
                onClear={handleClear}
                onChange={handleChangeInput}
                placeholder={i18n._(/* i18n */ 'Search for project')}
                autoFocus={autoFocus}
                onFocus={(e) => {
                    e.target.select()
                    openPopover()
                    setIsFocus(true)
                    setIsDropdownOpen(true)
                }}
                onBlur={(e) => {
                    setIsFocus(false)
                    setIsDropdownOpen(false)
                    if (e.relatedTarget) {
                        closePopover()
                    }
                }}
            />
        </Select>
    )
}

export default ProjectSearchSelect
