import React from 'react'
import { Trans } from '@lingui/macro'
import TextArea from 'components/util/TextArea'
import FormItem from 'components/util/FormItem'
import ProjectSearchSelect from 'components/util/ProjectSearchSelect'

const StartActivityForm = ({ formState, projectGuesses, isFetching }) => {
    const {
        values,
        getValue,
        getErrorMessages,
        getInputProps,
        getNativeInputProps,
        handleChangeProject,
    } = formState
    let invoicingLanguage = ''
    if (
        values.project !== null &&
        typeof values.project.invoicingLanguage !== 'undefined'
    ) {
        invoicingLanguage = `(${formState.values.project.invoicingLanguage.translation})`
    }
    return (
        <>
            <FormItem
                label={<Trans>Project</Trans>}
                error={getErrorMessages('project')}
            >
                <div className="flex flex-row items-center justify-between gap-x-4">
                    <ProjectSearchSelect
                        id="activityFormProjectSearchSelect"
                        {...getInputProps('project')}
                        defaultOptions={projectGuesses}
                        onChange={handleChangeProject}
                        disabled={isFetching}
                        className="flex-1"
                        position='bottom-start'
                    />
                </div>
            </FormItem>
            <FormItem
                label={<Trans>Description {invoicingLanguage}</Trans>}
                error={getErrorMessages('description')}
                required
            >
                <TextArea minRows="5" {...getNativeInputProps('description')} />
            </FormItem>
        </>
    )
}

export default StartActivityForm
